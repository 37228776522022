import React, { useState } from 'react';

export const quoteContext = React.createContext();

/* global window */
const Provider = (props) => {
  let quoteFormStorage = null;
  const [quoteForm, setquoteForm] = useState({});

  // if (typeof window !== 'undefined') {
  //   quoteFormStorage = JSON.parse(window.sessionStorage.getItem('quoteForm'));
  //   if (quoteFormStorage !== null && Object.keys(quoteForm).length === 0) {
  //     setquoteForm(quoteFormStorage);
  //   }
  // }

  return (
    <quoteContext.Provider value={{
      quoteForm,
      addToQuoteForm: (obj) => {
        setquoteForm(obj);
      },
      clearQuoteForm: () => {
        setquoteForm({});
      },
    }}>
      {props.children}
    </quoteContext.Provider>
  )
}

export default ({ element }) => (
  <Provider>
    {element}
  </Provider>
)